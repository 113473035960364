<template>
  <div>
    <h1>Logging in with JHU</h1>
  </div>
</template>

<script>
import axios from "axios";
export default {
  methods: {
    handleOauthCallback() {
      const vm = this;
      const redirect = vm.$store.getters.getRedirectPath;
      axios
        .post(process.env.VUE_APP_CLIENT_ID, { code: this.$route.query.code })
        .then((resp) => {
          vm.$store.commit("SET_AUTH", resp);
          vm.$store.dispatch("USER_REQUEST");
          vm.$router.push(redirect);
          vm.$store.dispatch("clearRedirectPath");
        });
    },
  },
  mounted() {
    this.handleOauthCallback();
  },
  // computed: {
  //   provider() {
  //     return this.$route.params.provider;
  //   },
  // },
};
</script>

<style lang="scss" scoped></style>
